import { SpaceDefinitionConfig } from "@/lib/rooms/base-types";
import { Vector3 } from "three";

const config: SpaceDefinitionConfig = {
  id: "mastercard-knight-exhibition-01",
  name: "Mastercard Knight Exhibition 01",
  description: "Mastercard Knight Exhibition Room",
  broadcast_slots: [
    {
      id: "broadcast-1",
      channelId: "lIhQcKFvkZeF7iMGlwvPLXqG7PLvvTTZknEuKhDbk9Ei9aj96f",
      objectId: "broadcast-flat-screen-1",
      materialId: "branding-slot-1"
    }
  ],
  screen_share_slots: [
    {
      id: "screenshare-1",
      objectId: "screenshare-flat-screen-2",
      materialId: "branding-slot-1"
    }
    // {
    //   id: "screenshare-2",
    //   objectId: "curved-screen-1",
    //   materialId: "branding-slot-1"
    // }
  ],
  teleport_pads: [
    {
      id: "pad-1",
      modelUrl: "/models/teleport-pad.glb",
      position: new Vector3(0, 0, 16)
    },
    {
      id: "pad-2",
      modelUrl: "/models/teleport-pad.glb",
      position: new Vector3(0, 0, -16)
    }
  ],
  branding_slots: [
    {
      id: "Image Curved Slot 1",
      type: "image",
      objectId: "curved-screen-1",
      materialId: "branding-slot-1",
      configuratorCameraPosition: new Vector3(-5, 2, -16)
    },
    {
      id: "Video Curved Slot 2",
      type: "video",
      objectId: "curved-screen-2",
      materialId: "branding-slot-1",
      configuratorCameraPosition: new Vector3(5, 2, -16)
    },
    {
      id: "Image Curved Slot 3",
      type: "image",
      objectId: "curved-screen-3",
      materialId: "branding-slot-1",
      configuratorCameraPosition: new Vector3(5, 2, 8)
    },
  ],
  assets: [
    {
      id: "gallery-1",
      url: "/models/rooms/mastercard_knight_exhibition.glb",
      type: "gltf"
    },

    {
      id: "broadcast-flat-screen-1",
      url: "/models/flat-screen-v2.glb",
      type: "gltf",
      position: new Vector3(0, 3.0, 32),
      rotation: new Vector3(0, 0, 0)
    },
    {
      id: "screenshare-flat-screen-2",
      url: "/models/flat-screen-v2.glb",
      type: "gltf",
      position: new Vector3(0, 3.0, -32),
      rotation: new Vector3(0, 180, 0)
    },
    {
      id: "curved-screen-1",
      url: "/models/curved-screen-display-v2.glb",
      type: "gltf",
      position: new Vector3(-11.3, 0.0, -16),
      rotation: new Vector3(0, -90, 0)
    },
    {
      id: "curved-screen-2",
      url: "/models/curved-screen-display-v2.glb",
      type: "gltf",
      position: new Vector3(11.3, 0.0, -16),
      rotation: new Vector3(0, 90, 0)
    },
    {
      id: "curved-screen-3",
      url: "/models/curved-screen-display-v2.glb",
      type: "gltf",
      position: new Vector3(11.5, 0.0, 8.5),
      rotation: new Vector3(0, 120, 0)
    },
  ]
};

export default config;
